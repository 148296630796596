

/* MODALS */
.ant-mo4dal-content {
    border-radius: 12px !important;
}

.ant-modal-header {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

.ant-modal-mask {
    backdrop-filter: blur(5px);
}

.ant-modal-mask {
    background-color: rgba(77, 75, 75, 0.5);
}

.ant-modal-header {
    background-color: var(--modal-background);
    color: var(--text-color);
}

.ant-modal-title {
    color: var(--text-color);
}

.ant-modal-content {
    background-color: var(--modal-background);
    color: var(--text-color);
}

.ant-modal-body {
    background-color: var(--modal-background);
    color: var(--text-color);
    border-radius: 4px;

}
/**********************/

/* TYPOGRAPHY */
.ant-typography {
    color: var(--text-color);
}

.ant-typography.ant-typography-secondary {
    color: var(--secondary-text-color);
}

.ant-modal-body > .ant-typography > p {
    color: var(--text-color)
}

/* *************** */

.ant-empty-description {
    color: var(--text-color);
}

.ant-modal-close-x {
    color: var(--icon-color)
}

/* BUTTONS */

.ant-btn-dangerous {
    background-color: #FFFFFF;

    &:hover {
        background-color: #FFFFFF;
    }

    &:focus {
        background-color: #FFFFFF;
    }
}

/* .ant-btn-dangerous {
    background-color: #E90000 !important;
    border-color: #E90000 !important;
    color: #FFFFFF !important;
}

.ant-btn-dangerous.ant-btn-link {
    color: #E90000 !important;
    border-color: transparent !important;
    background: transparent !important;
    box-shadow: none !important;
}

.ant-btn-dangerous.ant-btn-link:hover {
    color: #ff4d4f !important;
    border-color: transparent !important;
    background: transparent !important;
    box-shadow: none !important;
} */
/************************/

.ant-radio-inner {
    background-color: #ffffff !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    flex: 0 0 auto !important;
    transition: transform 0.3s;
}

.ant-steps-item-finish .ant-steps-icon-dot {
    background: var(--primary-color) !important;
}
.ant-steps-item-process .ant-steps-icon-dot {
    background: var(--primary-color) !important;
}

.ant-steps-item-finish .ant-steps-item-tail::after {
    background: var(--primary-color) !important;
}

@media (max-width: 767px) {
    .ant-steps-item-container {
        min-width: 10px;
        max-width: 80px;
    }

    .ant-steps {
        margin-left: -80px !important;
    }
}

.ant-picker-panel-container .ant-picker-panels {
    display: flex;
    flex-wrap: wrap ;
    justify-content: center;
}

.antd-popover-no-padding .ant-popover-inner-content {
    padding: 0;
}

.antd-popover-border-radius-8 .ant-popover-inner {
    border-radius: 8px;
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    z-index: 9999999;
}
.row-dragging td {
    padding: 16px;
}
.row-dragging .drag-visible {
    visibility: visible;
}

/* segmented */
.ant-segmented .ant-segmented-item.ant-segmented-item-selected {
    color: #006aff;
}