/* THEME DEFAULTS */
:root {
  --text-color: '#404040';
  --secondary-text-color: '#808080';
  --background-color: '#fff';
  --primary-color: '#0F6BFF';
  --modal-background: '#fff';
  --button-background: '#bdbdbd';
  --icon-color: '#404040';
  --fw-live-helper-bottom: 100px;
  --gray-14: '#e2e2e2';
}

/* <fw-ava> customizations */
:root {
  --fw-ava-color: #ffffff;
  --fw-ava-border-color: #ffffff;
  --fw-ava-padding-y: 0px;
  --fw-ava-padding-x: 24px;
  --fw-ava-height: 40px;
  --fw-ava-font-size: 14px;
  --fw-ava-font-weight: 600;
  --fw-ava-line-height: 1.5715;
  --fw-ava-border-radius: 4px;
}

body {
  background-color: rgb(240, 242, 245) !important;
  color: var(--text-color);
}
::selection {
  background: #a5d1fb;
  color: #000;
}
input::selection {
  background: #a5d1fb;
  color: #000;
}

code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}

img {
  display: block;
  max-width: 100%;
}

button {
  cursor: pointer;
}
button:active,
button:focus {
  outline: 0;
  outline-style: none;
  -moz-outline-style: none;
}

hr {
  height: 1px;
  margin: 4px 0px;
  border: none;
  background-color: #ebedf0;
}

a:active,
a:focus {
  outline: 0;
  outline-style: none;
  -moz-outline-style: none;
}

[data-reach-popover] {
  z-index: 1000;
}

[data-reach-menu-list] {
  padding: 4px 0px;
  white-space: inherit;
  word-break: break-word;
}

.react-icons {
  vertical-align: middle;
}

.EmbedCode-content {
  padding-bottom: 15px;
  margin: 0 auto;
  display: table;
  box-sizing: border-box;
  width: 100%;
}

.EmbedCode-container {
  display: table-cell;
  background: #fff;
  margin: 0 6px;
  border-radius: 4px 0 0 4px;
  border: 1px solid #ccd6dd;
  border-width: 1px 0 1px 1px;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 25px;
}

.EmbedCode-code {
  position: absolute;
  top: 0;
  color: #8899a6;
  padding: 3px 4px 4px 15px;
  overflow: hidden;
  display: block;
  font-size: 14px;
  font-family: 'Avenir Next';
  cursor: pointer;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.visor-metric {
  font-size: 3.5vw;
}

@media (max-width: 576px) {
  .visor-metric {
    font-size: 14vw;
  }
}

.sortableHelper {
  z-index: 20;
}

.fw-notification {
  padding: 10px;
  text-align: right;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #717171;
}

/* Tooltip override class */
.custom-tooltip {
  max-width: 500px !important;
}

.poster-dragger.ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}

.cta-type-select .ant-select-selector {
  background-color: #fafafa !important;
}

.command-palette {
  z-index: 20;
}
